import React, {useState, useEffect, Suspense} from 'react';

import './App.css';
import './app.scss';

import { Content } from 'carbon-components-react/lib/components/UIShell';

import Header from './components/BNIHeader';
import SideNav from './components/BNISideNav';
import CenteredLoader from './components/CenteredLoader';

import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from './utils/firebase_config';

try{firebase.initializeApp(firebaseConfig)} catch (e){}
try{firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)} catch(e) {}

const InquiryPage = React.lazy(() => import('./containers/InquiryPage'));
const SettingsPage = React.lazy(() => import('./containers/SettingsPage'));
const ReportPage = React.lazy(() => import('./containers/ReportPage'));
const SearchResultPage = React.lazy(() => import('./containers/SearchResultPage'));
const LoginPage = React.lazy(() => import('./containers/LoginPage'));
const LogoutPage = React.lazy(() => import('./containers/LogoutPage'));
const EditNasabahPage = React.lazy(() => import('./containers/EditNasabahPage'));
const EditProvinsiPage = React.lazy(() => import('./containers/EditProvinsiPage'));
const EditMajelisPage = React.lazy(() => import('./containers/EditMajelisPage'));

const App = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [dashUser, setDashUser] = useState(null);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) setDashUser(true);
    })
  }, [])

  const PrivateRoute = ({ component: Component, ...rest }) => {
      const isLoggedIn = firebase.auth().currentUser;
      return <Route {...rest} render={(props) => (
        isLoggedIn
          ? <Component {...props} />
          : <Redirect to='/' />
      )} />  
  }

  return (
    <>
      <Header />
      {
        firebase.auth().currentUser &&
        <>
          <SideNav />
        </>
      }
      <Content>
        <Suspense fallback={<CenteredLoader />}>
          <Switch>
            <PrivateRoute path="/inquiry" component={InquiryPage} />
            <PrivateRoute path="/settings/editNasabah" component={EditNasabahPage} />
            <PrivateRoute path="/settings/editProvinsi" component={EditProvinsiPage} />
            <PrivateRoute path="/settings/editMajelis" component={EditMajelisPage} />
            <PrivateRoute path="/settings" component={SettingsPage} />
            <PrivateRoute path="/report" component={ReportPage} />
            <PrivateRoute path="/search" component={SearchResultPage} />
            <PrivateRoute path='/logout' component={LogoutPage} />
            <Route exact path='/' component={LoginPage} />
          </Switch>
        </Suspense>
      </Content>
    </>
  );
}

export default withRouter(App);
