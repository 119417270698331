import React from 'react';
import {
  Header,
  HeaderName,
  // HeaderNavigation,
  // HeaderMenuItem,
  HeaderGlobalBar,
  // HeaderGlobalAction,
  SkipToContent,
  // SideNav,
  // SideNavDetails,
  // SideNavFooter,
  // SideNavHeader,
  // SideNavIcon,
  // SideNavItem,
  // SideNavItems,
  // SideNavLink,
  // SideNavLinkText,
  // SideNavMenu,
  // SideNavMenuItem,
  // SideNavSwitcher,
} from 'carbon-components-react/lib/components/UIShell';

// Icons
// import Notification20 from '@carbon/icons-react/lib/notification/20';
// import UserAvatar20 from '@carbon/icons-react/lib/user--avatar/20';
// import AppSwitcher20 from '@carbon/icons-react/lib/app-switcher/20';
import BNILogo from '../../assets/logo_bni_white.png';
import NUHKLogo from '../../assets/logo_nuhk_resize_white.png';

import { Link } from 'react-router-dom';


const BNIHeader = () => (
  <Header aria-label="Kartanu-HK Dashboard" id="header">
    <SkipToContent />
    <img src={BNILogo} id="bni-header-logo" className="logo" alt="Logo BNI" />
    <HeaderName element={Link} to="/" prefix="BNI">
      Kartanu-HK Dashboard
    </HeaderName>
    {/* <HeaderNavigation aria-label="Kartanu-HK Dashboard">
      <HeaderMenuItem element={Link} to="/">Inquiry</HeaderMenuItem>
      <HeaderMenuItem element={Link} to="/report">Report</HeaderMenuItem>
      <HeaderMenuItem element={Link} to="/settings">Settings</HeaderMenuItem>
    </HeaderNavigation> */}
    <HeaderGlobalBar>
    </HeaderGlobalBar>
    <img src={NUHKLogo} id="nuhk-header-logo" className="logo" alt="Logo NUHK" />
  </Header>
);

export default BNIHeader;
