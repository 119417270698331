const firebaseConfig = {
  apiKey: "AIzaSyBPKMm5S5W-UYp1_gA-elM5fTsYpJfaadQ",
  authDomain: "kartanu-hk.firebaseapp.com",
  databaseURL: "https://kartanu-hk.firebaseio.com",
  projectId: "kartanu-hk",
  storageBucket: "kartanu-hk.appspot.com",
  messagingSenderId: "949753864976",
  appId: "1:949753864976:web:80d8ac2ef95bfbff09bb95",
  measurementId: "G-9992QG4DJW"
};
export default firebaseConfig;